import {FC, useCallback} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {TypeEnum} from "../../../types/answerTemplate";
import {useActions} from "../../../hooks/useActions";
import {useParams} from "react-router-dom";
import validatePhone from "../../../helpers/validatePhone";
import validateEmail from "../../../helpers/validateEmail";
import {Button, message} from "antd";
import getInvertedColorStyle from "../../../helpers/getInvertedColorStyle";
import {I18n} from "react-redux-i18n";
import validateIin from "../../../helpers/validateIin";
import {testValueUsingCustomRegExp} from "../../../helpers/testValueUsingCustomRegExp";

const OTHER_ANSWER_KEY = "other";

type Props = {
  readonly loading: boolean;
};

const ButtonsComponent: FC<Props> = ({ loading }) => {
  const { instanceId } = useParams();
  const { answerQuestion } = useActions();
  const { question, answer, customAnswer } = useSelector((state: RootState) => state.questions);

  const isTemplate = useCallback(
    (type$: TypeEnum) => question?.answerTemplate?.type === type$,
    [question?.answerTemplate?.type],
  );

  const isAnswerValid = useCallback(() => {
    if (
      isTemplate(TypeEnum.EMAIL) ||
      isTemplate(TypeEnum.IIN) ||
      isTemplate(TypeEnum.NUMBER) ||
      isTemplate(TypeEnum.TEXT) ||
      isTemplate(TypeEnum.DATE) ||
      isTemplate(TypeEnum.DATETIME)
    ) {
      if (!question?.answerTemplate?.customAnswerIsRequired && !answer?.answerValue) {
        return true;
      }
      if (!answer?.answerValue) {
        return false;
      }

      if (isTemplate(TypeEnum.NUMBER)) {
        return validatePhone(answer.answerValue);
      } else if (isTemplate(TypeEnum.IIN)) {
        return validateIin(answer.answerValue);
      } else if (isTemplate(TypeEnum.EMAIL)) {
        return validateEmail(answer.answerValue);
      } else if (isTemplate(TypeEnum.TEXT) && question?.answerTemplate?.customRegexp) {
        return testValueUsingCustomRegExp({
          regexp: question.answerTemplate.customRegexp,
          value: answer?.answerValue,
        });
      }
      return true;
    } else if (isTemplate(TypeEnum.SINGLE_CHOICE) || isTemplate(TypeEnum.MULTIPLE_CHOICE)) {
      if (!question?.answerTemplate?.customAnswerIsRequired) {
        return true;
      }
      return !!answer?.choices || !!answer?.answerValue;
    } else if (isTemplate(TypeEnum.CSI)) {
      if (!question?.answerTemplate?.customAnswerIsRequired) {
        return true;
      }
      return answer?.csiAnswers && answer.csiAnswers.length > 0
          ? answer.csiAnswers.every((csiAnswer) =>
              csiAnswer?.criterias?.every((criteria) => criteria?.score !== undefined)
          )
          : false;
    } else if (
      !isTemplate(TypeEnum.START_BLOCK) &&
      !isTemplate(TypeEnum.END_BLOCK) &&
      !isTemplate(TypeEnum.INFO_BLOCK)
    ) {
      return !!answer?.answerValue || !!customAnswer;
    }
    return true;
  }, [question, answer, customAnswer, isTemplate]);

  const handleAnswer = useCallback(() => {
    const answerValid = isAnswerValid();
    if (!answerValid) {
      return message.error(I18n.t("answerRequired"));
    }
    if (instanceId && question?.questionId) {
      let answerObj = answer;

      if (isTemplate(TypeEnum.SINGLE_CHOICE) || isTemplate(TypeEnum.MULTIPLE_CHOICE)) {
        const choices = answer?.choices?.map((it) => String(it)) ?? [];
        answerObj = {
          csiAnswers: [],
          answerValue: customAnswer,
          choices: !customAnswer ? choices : [...choices, OTHER_ANSWER_KEY],
        };
      } else if (isTemplate(TypeEnum.IIN)) {
        answerObj = { ...answer, answerValue: answer?.answerValue?.replace(/\s/g, "") };
      }

      answerQuestion({
        instanceId,
        answer: answerObj,
        questionId: question.questionId,
      });
    }
  }, [instanceId, question, customAnswer, answer, isTemplate, isAnswerValid]);

  return (
    <div className={"btn-container"}>
      <Button type={"primary"} onClick={handleAnswer} loading={loading}>
        <span style={getInvertedColorStyle()}>{question?.answerTemplate?.actionLabel ?? I18n.t("next")}</span>
      </Button>
    </div>
  );
};

export default ButtonsComponent;
